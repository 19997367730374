import Layout from '../components/layout';
import React from 'react';
import SEO from '../components/seo';

const ContactPage = ({ location }) => (
  <Layout bgOpacity={0.8} location={location}>
    <SEO title="Contact" keywords={[`contact`, `contact info`]} />

    <div className="flex flex-col w-full w-full md:max-w-md">
      <h1 className="text-grey-600">Get in touch</h1>

      <form
        className="flex flex-col md:flex-row flex-wrap w-full mx-auto text-grey-600"
        method="post"
        action="/success/"
        data-netlify="true"
        data-netlify-honeypot="secret-field"
        name="contact"
        id="contactForm"
      >
        <input type="hidden" name="secret-field" />

        <div className="w-full md:w-1/2 md:px-2">
          <label className="block font-bold text-xs" htmlFor="first-name">
            First Name
          </label>
          <input
            className="appearance-none w-full bg-white border border-grey-300 rounded text-grey-600 mb-6 p-3"
            id="first-name"
            type="text"
            placeholder="Bill"
            required
          />
        </div>

        <div className="w-full md:w-1/2 md:px-2">
          <label className="block font-bold text-xs" htmlFor="last-name">
            Last Name
          </label>
          <input
            className="appearance-none w-full bg-white border border-grey-300 rounded text-grey-600 mb-6 p-3"
            id="last-name"
            type="text"
            placeholder="Murray"
            required
          />
        </div>

        <div className="w-full md:w-1/2 md:px-2">
          <label className="block font-bold text-xs" htmlFor="email">
            Email
          </label>
          <input
            className="appearance-none w-full bg-white border border-grey-300 rounded text-grey-600 mb-6 p-3"
            id="email"
            type="email"
            placeholder="bill.murray@gmail.com"
            required
          />
        </div>

        <div className="w-full md:w-1/2 md:px-2">
          <label className="block font-bold text-xs" htmlFor="last-name">
            Phone
          </label>
          <input
            className="appearance-none w-full bg-white border border-grey-300 rounded text-grey-600 mb-6 p-3"
            id="phone"
            type="text"
            placeholder="555-555-5555"
            pattern="\d{3}[\-]\d{3}[\-]\d{4}"
            title="Format: 555-555-5555"
          />
        </div>

        <div className="w-full md:px-2">
          <label className="block font-bold text-xs" htmlFor="message">
            Message
          </label>
          <textarea
            className="appearance-none w-full bg-white border border-grey-300 rounded text-grey-600 mb-6 p-3"
            placeholder="Tell me what you need..."
            rows="8"
            required
          />
        </div>

        <button
          className="border border-grey-300 hover:border-grey-600 text-grey-600 hover:text-black md:mx-2 px-6 py-3 rounded"
          type="submit"
        >
          Submit
        </button>
      </form>
    </div>
  </Layout>
);

export default ContactPage;
